import React, { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { NexusService } from 'global-apis/nexus-service'
import {
  formattedCustomerInfo,
  formattedCustomerName,
  FormattedCustomerInfo,
} from './useCustomers.utils'

export function useCustomer() {
  const { customerId } = useParams<{ customerId: string }>()

  const { data: customer } = useQuery(
    ['/customers', customerId],
    async () => {
      const customerDetails = await NexusService.getCustomer({ customerId }).then((res) => {
        return {
          customerId: customerId,
          customerName: formattedCustomerName(res),
          customerInfo: formattedCustomerInfo(res),
          isCustomerError: false,
        }
      })

      const subscriptionIds = await NexusService.listCustomerSubscriptions(customerId).then((res) => {
        return res.subscriptionsList.map((subscription) => {
          return subscription.id
        })
      })

      const firstSubscriptionId = subscriptionIds[0]
      // Multiple subscriptions on customer not supported in nexus yet.
      // Log error if there is more than one subscription
      if (subscriptionIds.length > 1) {
        console.error('Customer has more than one subscription')
      }

      const subscriptionDetails = await NexusService.getSubscriptionById(firstSubscriptionId).then((res) => {
        return {
          subscriptionId: firstSubscriptionId,
          subscriptionStatus: res.status,
          recurringBillingDay: res.recurringBillingDay,
        }
      })

      return {
        ...customerDetails,
        ...subscriptionDetails,
      }
    },
    {
      placeholderData: {
        customerId: '',
        customerName: '',
        customerInfo: {} as FormattedCustomerInfo,
        subscriptionStatus: '',
        subscriptionId: '',
        recurringBillingDay: 0,
        isCustomerError: false,
      },
    }
  )

  return {
    customerId,
    customerName: customer?.customerName,
    customerInfo: customer?.customerInfo,
    subscriptionStatus: customer?.subscriptionStatus,
    subscriptionId: customer?.subscriptionId,
    recurringBillingDay: customer?.recurringBillingDay,
    isCustomerError: customer?.isCustomerError,
  }
}

//@ts-ignore
const CustomerContext = createContext<ReturnType<typeof useCustomer>>()
// ! An optional step that we might consider using? Makes it easier to identify it in react dev tools
CustomerContext.displayName = 'CustomerContext'

export const useCustomerContext = () => {
  return useContext(CustomerContext)
}

export const CustomerContextProvider = ({ children }) => {
  const value = useCustomer()

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  )
}
