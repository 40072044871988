import React from 'react'
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { autonomyColor } from '../../global-styles/autonomyColor'
import { useCustomerContext } from './CustomerContext'

export const CustomerInfo = () => {
  const { customerInfo, subscriptionStatus } = useCustomerContext()

  return (
    <Table size={'small'}>
      <TableHead>
        <TableRow>
          <TableCell>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Link color={autonomyColor.pink} variant="subtitle2">
                  Basic Details
                </Link>
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Link color="inherit" variant="subtitle2"></Link>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>

        <TableRow hover>
          <TableCell>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Link color={autonomyColor.white} variant="subtitle2">
                  Email
                </Link>
              </Box>
            </Box>
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>{customerInfo?.email}</TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Link color={autonomyColor.white} variant="subtitle2">
                  Phone
                </Link>
              </Box>
            </Box>
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>{customerInfo?.phone}</TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Link color={autonomyColor.white} variant="subtitle2">
                  Parking Address
                </Link>
              </Box>
            </Box>
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>{customerInfo?.parkingAddress}</TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Link color={autonomyColor.white} variant="subtitle2">
                  License Address
                </Link>
              </Box>
            </Box>
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>{customerInfo?.licenseAddress}</TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Link color={autonomyColor.white} variant="subtitle2">
                  Contact Address
                </Link>
              </Box>
            </Box>
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>{customerInfo?.contactAddress}</TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Link color={autonomyColor.white} variant="subtitle2">
                  Subscription status
                </Link>
              </Box>
            </Box>
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>{subscriptionStatus}</TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Link color={autonomyColor.white} variant="subtitle2">
                  Account Number
                </Link>
              </Box>
            </Box>
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>{customerInfo?.accountNumber}</TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Box sx={{ ml: 1 }}>
                <Link color={autonomyColor.white} variant="subtitle2">
                  Driver&apos;s License Exp. Date
                </Link>
              </Box>
            </Box>
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>{customerInfo?.liscenceExpirationDate}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
