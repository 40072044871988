import {
  SubscriptionServicePromiseClient,
  CustomerSubscriptionPricingPromiseClient,
} from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'
import { ListProgramPricingOptionsRequest } from '@nxcr-org/web-api-interface/lib/spre_pb'
import { oktaAuth, OktaAuthInterceptor } from '../../../okta/config'
import { Env } from './env'
import { PricingOption } from '@nxcr-org/web-api-interface/lib/subscription_pb'
import { UpdateReservationRequest } from '@nxcr-org/web-api-interface/lib/subscription_service_pb'
import {
  TeslaModel3Options,
  TeslaModelYOptions,
} from '@nxcr-org/web-api-interface/lib/subscription_pb'

function subscriptionClient(): SubscriptionServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new SubscriptionServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

function customerSubscriptionClient(): CustomerSubscriptionPricingPromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new CustomerSubscriptionPricingPromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

// ! Reservation fee was added during refactoring of subscriptions, is not currently used by backend. Code will need to be refactored when
type DeprecatedPricingOption = Omit<PricingOption.AsObject, 'reservationFee'>

export type PricingRequest = {
  reservationId: string
  pricingOption: DeprecatedPricingOption
}

type ModelOptions = TeslaModel3Options | TeslaModelYOptions
type ModelOptionsObject = ReturnType<ModelOptions['toObject']>
function buildModelOptions(pricingOptionObject: Partial<ModelOptionsObject>) {
  const {
    deposit,
    monthly,
    startFee,
    color,
    secondaryColor,
    reservationFee,
    priceTermMonths,
  } = pricingOptionObject

  const pricingOption = new TeslaModel3Options()

  pricingOption.setDeposit(deposit)
  pricingOption.setMonthly(monthly)
  pricingOption.setStartFee(startFee)
  pricingOption.setColor(color)
  pricingOption.setSecondaryColor(secondaryColor)
  pricingOption.setReservationFee(reservationFee)
  pricingOption.setPriceTermMonths(priceTermMonths)

  return pricingOption
}

async function updatePricing(
  params: Partial<UpdateReservationRequest.AsObject>,
  modelOptionsObject: Partial<ModelOptionsObject>
) {
  const client = subscriptionClient()
  const request = new UpdateReservationRequest()

  request.setReservationId(params.reservationId)
  request.setStatus(params.status)
  request.setProgramId(params.programId)

  request.setModel3Options(buildModelOptions(modelOptionsObject))

  return client.updateReservation(request)
}

async function getProgramPricingOptions() {
  const client = customerSubscriptionClient()
  const pricingRequest = new ListProgramPricingOptionsRequest()

  pricingRequest.serializeBinary()

  return client.listProgramPricingOptions(pricingRequest).then((response) => {
    return response.toObject()
  })
}

/**
 * This is a temporary measure until the backend is updated to return program pricing on a per program basis.
 * @returns
 */
async function getTempProgramPricingOptions(): Promise<
  Record<string, DeprecatedPricingOption[]>
> {
  const client = customerSubscriptionClient()
  const pricingRequest = new ListProgramPricingOptionsRequest()

  pricingRequest.serializeBinary()

  return client.listProgramPricingOptions(pricingRequest).then((response) => {
    //TODO: get the pricing options from the reservation
    return {
      TeslaModel3: response.toObject().pricingOptionsList,
      TeslaModelY: [
        {
          startFee: 690000,
          monthly: 69000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 630000,
          monthly: 73000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 570000,
          monthly: 75000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 510000,
          monthly: 80000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 450000,
          monthly: 85000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 390000,
          monthly: 89000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 330000,
          monthly: 93000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 270000,
          monthly: 102000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 210000,
          monthly: 108000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 150000,
          monthly: 115000,
          id: '',
          deposit: 0,
        },
      ],
      TeslaModel3LongRange: [
        {
          startFee: 590000,
          monthly: 59000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 510000,
          monthly: 66000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 440000,
          monthly: 73000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 370000,
          monthly: 80000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 310000,
          monthly: 86000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 260000,
          monthly: 91000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 220000,
          monthly: 96000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 180000,
          monthly: 101000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 140000,
          monthly: 106000,
          id: '',
          deposit: 0,
        },
        {
          startFee: 100000,
          monthly: 110000,
          id: '',
          deposit: 0,
        },
      ],
    }
  })
}

// making up for bad API design...
export const teslaModel = {
  model3: 'Model3',
  modelY: 'ModelY',
}

export const CustomerSubscription = {
  updatePricing,
  getProgramPricingOptions,
  getTempProgramPricingOptions,
}
