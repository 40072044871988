import { map } from 'lodash'
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import * as React from 'react'
import { useActivationStatus } from './useActivationStatusBlock'

export interface ActivationStatusBlockProps {
  currentStatus: number
  isQualified: boolean
  currentActivity?: string
  customerId: string
  subscriptionId: string
}

export const ActivationStatusBlock: React.FC<ActivationStatusBlockProps> = ({
  isQualified,
  customerId,
  subscriptionId,
}) => {
  const {
    statuses,
    statusCol2,
    billingStatusError,
    subscriptionStatusError,
  } = useActivationStatus({
    isQualified,
    customerId,
    subscriptionId,
  })
  return (
    <Grid direction="column" container>

      <Grid item style={{ height: 15 }}></Grid>
      <Grid item>
        <Grid container>
          <Grid item xs={12} sm={6}>
            {subscriptionStatusError ? (
              <div>
                <p>Error loading statuses</p>
              </div>
            ):(
              <FormGroup>
                {map(statuses, (status) => (
                  <FormControlLabel
                    className={`resetHoverStyle compactCheckboxList ${
                      status.active ? 'active' : ''
                    }`}
                    control={<Checkbox checked={status.active} color="primary" />}
                    key={status.label}
                    label={status.label}
                  />
                ))}
              </FormGroup>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {billingStatusError ? (
              <div>
                <p>Error loading statuses</p>
              </div>
            ):(
              <FormGroup>
                  {map(statusCol2,
                    (status) => (
                      <FormControlLabel
                        className={`resetHoverStyle compactCheckboxList ${
                        status.active ? 'active' : ''
                      }`}
                      control={
                        <Checkbox checked={status.active} color="primary" />
                      }
                      key={status.label}
                      label={status.label}
                    />
                  )
                )}
              </FormGroup>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
