import React from 'react'
import {
  Container,
  Backdrop,
  CircularProgress,
  Typography,
  Button,
} from '@mui/material'
import { ActivationStatusBlock } from './blocks/ActivationStatusBlock/ActivationStatusBlock'
import { CustomerBlock } from './blocks/CustomerBlock'
import { QualificationBlock } from './blocks/QualificationBlock/QualificationBlock'
import { RecordUpdateBlock } from './blocks/QualificationBlock/RecordUpdateBlock'
import { VehicleBlock } from './blocks/VehicleBlock'
import { ProgramPricingBlock } from './blocks/ProgramPricingBlock/ProgramPricingBlock'
import { CancellationBlock } from './blocks/CancellationBlock/CancellationBlock'

import { useReservation } from './useReservation'
import { ActivityState } from '@nxcr-org/web-api-interface/lib/domain_pb'

import { BackButton } from '../../../../components/user-navigation/BackButton'
import { Article } from 'shared/Article'
import { Section } from 'shared/Section'
import { ChangeProgramDialog } from './blocks/ChangeProgramDialog/ChangeProgramDialog'
import { FeatureFlags } from '../../../../config/flags'

import { AppointmentBlock } from './blocks/AppointmentBlock/AppointmentBlock'
import { AssignmentBlock } from './blocks/AssignmentBlock/AssignmentBlock'
import { InsuranceBlock } from './blocks/InsuranceBlock/InsuranceBlock'

const ReservationPage: React.FC = () => {
  const {
    reservation,
    isAdmin,
    overrideQualification,
    overrideQualificationSuccess,
    mostRecentNote,
    currentNote,
    setCurrentNote,
    recalculateCustomerScores,
    recalculateCustomerScoresSuccess,
    setReload,
    assignVehicle,
    unassignVehicle,
    addNote,
    cancelReservation,
    cancelError,
    isLoading,
    reservationError,
    toggleChangeProgram,
    changeProgramOpen,
    cqeScores,
    disqualificationVendor,
    overrideDisqualificationVendor,
    setOverrideDisqualificationVendor,
  } = useReservation()

  return (
    <Container maxWidth={false}>
      <Backdrop
        open={isLoading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
      <BackButton lastPageRoute="/fleet/reservations" />
      <Article
        heading={
          <Typography variant="h1">
            Reservation Details /{' '}
            <em>{reservation?.assignedVehicle?.model || ''}</em>
          </Typography>
        }
        headerHasBottomBorder={true}
        actionBlock={
          FeatureFlags.fleet488 && (
            <Button onClick={toggleChangeProgram} variant="contained">
              Switch Programs
            </Button>
          )
        }
      >
        {reservationError ? (
          <Typography>{reservationError['message']}</Typography>
        ) : (
          <>
            <Section heading="Customer">
              <CustomerBlock {...reservation} />
            </Section>
            <Section heading="Vehicle">
              <VehicleBlock {...reservation.vehicle} />
            </Section>
            <Section heading="Activation status">
              <ActivationStatusBlock
                currentActivity={reservation.customerInfo.currentActivity}
                currentStatus={reservation.reservation.status}
                isQualified={reservation.isQualified}
                customerId={reservation?.customer.id}
                subscriptionId={reservation?.subscriptionId}
              />
            </Section>
            {FeatureFlags.aty310 &&
              reservation?.customerInfo?.accountNumber && (
                <AppointmentBlock reservation={reservation} />
              )}
            <QualificationBlock />
            {(reservation.qualifications?.hasQualifications || isAdmin) && (
              <Section heading="Update Record" subSection>
                <RecordUpdateBlock
                  currentTier={reservation.customerInfo.qualificationTier}
                  overrideQualification={overrideQualification}
                  overrideQualificationSuccess={overrideQualificationSuccess}
                  note={currentNote}
                  setNote={setCurrentNote}
                  mostRecentNote={mostRecentNote}
                  addNote={addNote}
                  customer={reservation?.customer}
                  recalculateCustomerScores={recalculateCustomerScores}
                  recalculateCustomerScoresSuccess={
                    recalculateCustomerScoresSuccess
                  }
                  isAdmin={isAdmin}
                  cqeScores={cqeScores}
                  disqualificationVendor={disqualificationVendor}
                  overrideDisqualificationVendor={
                    overrideDisqualificationVendor
                  }
                  setOverrideDisqualificationVendor={
                    setOverrideDisqualificationVendor
                  }
                />
              </Section>
            )}
            <ProgramPricingBlock
              reservation={reservation}
              isAdmin={isAdmin}
              setReload={setReload}
            />
            <Section heading="Reservation Assignment">
              <AssignmentBlock
                customerHasVehicle={reservation.customerInfo.hasVehicleAssigned}
                customerTier={reservation.customerInfo.qualificationTier}
                reload={false}
                reservationLoading={isLoading}
                assignVehicle={(vehicleId) => {
                  assignVehicle(vehicleId)
                }}
                assignedVehicle={reservation?.assignedVehicle}
                unAssignVehicle={unassignVehicle}
                reservationStatus={reservation?.reservationStatus}
                program={reservation?.program}
              />
            </Section>
            <Section heading="Insurance">
              <InsuranceBlock subscriptionId={reservation?.subscriptionId} customerId={reservation?.customer?.id} />
            </Section>
            {reservation.customer.activityState !==
              ActivityState.SUBSCRIPTION_ACTIVATED && (
              <Section heading="Cancel Order">
                <CancellationBlock
                  isCancelled={reservation.isCancelled}
                  cancelSubscription={cancelReservation}
                  errorCancelling={cancelError}
                />
              </Section>
            )}
          </>
        )}
        {changeProgramOpen && (
          <ChangeProgramDialog
            isOpen={changeProgramOpen}
            onClose={toggleChangeProgram}
            currentProgram={reservation?.program}
            customerId={reservation?.customer?.id}
            reservationId={reservation?.reservation?.id}
          />
        )}
      </Article>
    </Container>
  )
}

export default ReservationPage
