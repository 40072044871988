import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { FormattedReservationResponse } from '../../useReservation'
import { CustomerSubscription } from '../../../../api/customerSubscription'
import { ActivityState } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { find } from 'lodash'
import { NexusService } from 'global-apis/nexus-service'
import {
  PricingOption,
  Program,
} from '@nxcr-org/web-api-interface/lib/subscription_pb'

export function useProgramPricingBlock(
  reservation: FormattedReservationResponse
) {
  const vehicleOptions = reservation?.vehicle

  const [isEdit, setIsEdit] = useState(false)
  const [monthlyPayment, setMonthly] = useState(vehicleOptions.monthly)
  const [startFee, setStartFee] = useState(vehicleOptions.startFee)
  const [selectedOption, setSelectedOption] =
    useState<PricingOption.AsObject>(null)

  useEffect(() => {
    setMonthly(vehicleOptions.monthly)
    setStartFee(vehicleOptions.startFee)
  }, [vehicleOptions.monthly, vehicleOptions.startFee])

  const { data: programOptions, error: programOptionsError } = useQuery(
    ['/programOptions'],
    () => {
      return NexusService.getProgramofferings()
    },
    {
      placeholderData: {
        programsList: [],
      },
      onError() {
        console.log('Error: ', programOptionsError)
      },
    }
  )

  const handleChange = (e) => {
    const selectedOption = find(
      getPricingOptionsForProgram(
        programOptions?.programsList,
        reservation?.program?.id
      ),
      {
        id: e.target.value,
      }
    )

    setSelectedOption(selectedOption)
    setMonthly(selectedOption.monthly)
    setStartFee(selectedOption.startFee)
  }

  const cancelChanges = () => {
    setIsEdit(false)
    setSelectedOption(null)
    setMonthly(vehicleOptions.monthly)
    setStartFee(vehicleOptions.startFee)
  }

  const isValidSubscriptionStatus = (reservation) => {
    return (
      reservation.customer.activityState ===
        ActivityState.SUBSCRIPTION_ACTIVATED ||
      reservation.customer.activityState ===
        ActivityState.SUBSCRIPTION_CANCELLED
    )
  }

  const modelOptions = {
    id: '',
    startFee,
    monthly: monthlyPayment,
    deposit: vehicleOptions?.deposit,
    color: vehicleOptions?.colorPreference,
    secondaryColor: reservation?.reservation?.model3?.secondaryColor,
    reservationFee: reservation?.reservation?.model3?.reservationFee,
    priceTermMonths: selectedOption
      ? selectedOption.priceTermMonths
      : vehicleOptions?.termLength,
  }

  const savePricing = useMutation(() => {
    if (!selectedOption?.id) {
      return Promise.reject('Select a new pricing option or cancel')
    }

    return CustomerSubscription.updatePricing(
      {
        reservationId: reservation?.reservation.id,
        status: reservation?.reservation.status,
        programId: reservation?.program.id,
      },
      modelOptions
    )
  })

  return {
    isEdit,
    setIsEdit,
    monthlyPayment,
    setMonthly,
    startFee,
    setStartFee,
    handleChange,
    cancelChanges,
    savePricing,
    isValidSubscriptionStatus,
    programPricingOptions: getPricingOptionsForProgram(
      programOptions?.programsList,
      reservation?.program?.id
    ),
  }
}

export function getPricingOptionsForProgram(
  programOptions: Program.AsObject[],
  programId: string
) {
  if (!programOptions.length || !programId) {
    return []
  }

  const programOption = programOptions?.find(
    (option) => option?.id === programId
  )

  const pricingOptions =
    programOption?.pricingOptionsList?.slice(0)

  return pricingOptions
}
